import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export function getQueryString(name) {
    var vars = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi,
        function (m, key, value) {
            vars[key] = value;
        }
    );
    return vars[name];
}

export function conversionUtcDate(date, type, isShort, format) {
    if (format) {
        return dayjs.utc(date).local().format(format)
    }
    else if (type === 'UTC') {  // 传入UTC则将时间转换为UTC时间
        return dayjs(date).utc().format()
    }
    else if (isShort === true) {
        return dayjs.utc(date).local().format('YYYY-MM-DD HH:mm')
    }
    return dayjs.utc(date).local().format('YYYY-MM-DD HH:mm:ss')
}

export function getWeekdayDesc(day) {
    const week = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
    return week[day];
}

export function getMonthDesc(index) {
    const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return month[index];
}

export function capitalizeFirstLetter(string) {
    if (typeof string !== 'string' || string.length === 0) {
        return '';
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function agentIsAndriod(agent) {
    return /android/i.test(agent);
}

export function agentIsIos(agent) {
    return /iPad|iPhone|iPod/.test(agent);
}

export function htmlImgSrc(htmlstr) {
    let imgList = [];
    htmlstr.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, (match, capture) => {
        imgList.push(capture);
        return '';
    });
    return imgList;
}

export function getParenthesesStr(text) {
    let result = '';
    let regex = /\((.+?)\)/g;
    let options = text.match(regex);
    if (options && options.length > 0) {
        let option = options[options.length - 1]
        if (option && option.length > 2) {
            result = option.substring(1, option.length - 1)
        }
    }
    if (result) return result;
    return text;
}