import { Drawer, Space, Button, Tag, Image, Popconfirm, message, Modal, Input, Tooltip, Skeleton } from "antd";
import { CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import dayjs from "dayjs";
import { useEffect, useState, useRef } from "react";
import { MainLayoutUrl } from "../../router";
import { useNavigate } from "react-router-dom";
import { deletePost, fetchCommentListStack, fetchMoreChildComment, updatePost } from "../../api/community";
import UserInfoView from "./user_info_view";
import { capitalizeFirstLetter } from "../../utils/comm";
import { PostStatusOptions } from "./post_pageview";
import TopicIcon from '../../assets/topic.svg';
import PostCommentsView from "./post_comment_view";
import { useDebouncedCallback } from "use-debounce";
import VirtualCommentForPostModalView from "./virtual_comment_for_post_modal_view";

const PostReview = ({ post, open, onClose, onUpdate, canEdit = true, from = 'default' }) => {
    const navigateTo = useNavigate();
    const [loading, setLoading] = useState(false);
    const [loadMore, setLoadMore] = useState(true);
    const [commentPageNum, setCommentPageNum] = useState(1);
    const [comments, setComments] = useState([]);
    const [commentsTotal, setCommentsTotal] = useState(0);
    const [currComment, setCurrComment] = useState();
    const [modalShow, setModalShow] = useState(false);
    const [commentPageSize, setCommentPageSize] = useState(10);
    const [commentLoading, setCommentLoading] = useState(false);

    const tooltipRef = useRef(null);
    useEffect(() => {
        setComments([]);
        setCommentPageNum(1);
        setCommentsTotal(0);
        setCurrComment(undefined);
        setCommentLoading(false);
        setLoadMore(true);
        if (post) {
            getComments(false, 1, commentPageSize);
            let ele = document.getElementById(`${from}_pcnt_${post.id}`);
            if (ele) {
                ele.innerHTML = '';
                let content = document.createElement('div');
                content.innerHTML = post.content;
                let scripts = content.querySelectorAll('script');
                scripts.forEach((ele) => ele.remove());
                ele.appendChild(content);
            }

            let scroll = document.getElementById(`scroll_${post.id}`);
            scroll.onscroll = () => {
                let diff = scroll.scrollHeight - (scroll.clientHeight + parseInt(scroll.scrollTop));
                if (diff <= 1) {
                    getComments(true);
                }
            }
        }
    }, [post])

    const getComments = useDebouncedCallback(
        (more, num, pSize) => {
            if (commentLoading) return;
            let pNum = num ? num : (more ? (commentPageNum + 1) : commentPageNum);
            if (!loadMore && pNum > 1) return;
            setCommentLoading(true);
            let params = {
                'page.num': pNum,
                'page.size': pSize ? pSize : commentPageSize,
                'postId': post.id
            }
            fetchCommentListStack(params).then(res => {
                if (res.list) {
                    setCommentsTotal(res.commentTotal);
                    if (pNum == 1) {
                        setComments(res.list);
                    } else {
                        setComments([...comments, ...res.list]);
                    }
                    if (Array.from(res.list).length < commentPageSize) {
                        setLoadMore(false);
                        setCommentPageNum(pNum > 1 ? pNum - 1 : 1);
                    } else {
                        setLoadMore(true);
                        setCommentPageNum(pNum);
                    }
                }
            }).finally(() => {
                setCommentLoading(false);
            })
        }, 300
    )

    const buildPostView = () => {
        let resources = Array.from((post.resource ?? '').split(','));
        let videoSrc = resources.find(item => item.includes('.mp4') || item.includes('.mp3'));
        resources = resources.filter(item => !item.includes('.mp4') && !item.includes('.mp3'));
        return (
            <>
                <h3>{post.title}</h3>
                {
                    videoSrc && <video style={{ width: '100%', height: 'auto' }} src={videoSrc} controls autoPlay={false} />
                }
                <Space wrap={true} align="start">
                    {
                        resources.map((item, index) => {
                            return <Image key={index} style={{ maxWidth: 120, maxHeight: 72, objectFit: 'contain' }} src={item} />
                        })
                    }
                </Space>
            </>
        )
    }

    const buildArticleView = () => {
        return (
            <>
                <h3>{post.title}</h3>
            </>
        )
    }

    const buildStatus = () => {
        let status = post.status;
        if (!isNaN(status)) {
            status = PostStatusOptions.find(item => item.value == status)?.label;
        }
        let reason = post.riskReason;
        if (status === 'RISK') {
            if (reason) {
                return (
                    <Tooltip ref={tooltipRef} title={reason} color='orange'>
                        <div>
                            <Tag color="red">RISK CONFIRM <CloseCircleOutlined /></Tag>
                        </div>
                    </Tooltip>
                )
            }
            return <Tag color="orange">RISK PENDING <InfoCircleOutlined /></Tag>
        }
        return <Tag style={{ fontSize: 12 }}>{status}</Tag>;
    }

    const rejectHandle = () => {
        let reason = '';
        let modal = Modal.confirm({
            closable: false,
            title: 'Are you sure to reject this post?',
            content: (
                <div>
                    <Input style={{ color: 'red' }} prefix={<span style={{ color: '#333333' }}>Reason: </span>} placeholder="reject reason" onChange={evt => {
                        reason = evt.target.value;
                        modal.update({
                            okButtonProps: {
                                disabled: !reason.trim(),
                            }
                        })
                    }} allowClear />
                </div>
            ),
            okText: 'Confirm',
            okButtonProps: {
                disabled: !reason.trim(),
            },
            cancelText: 'Cancel',
            onOk: async () => {
                let params = { ...post, status: 'RISK', riskReason: reason };
                delete params['adminTags'];
                delete params['tags'];
                delete params['events'];
                delete params['topic'];
                try {
                    await updatePost(post.id, params);
                    onUpdate();
                    onClose();
                } catch (error) {
                    message.error(String(error));
                }
            }
        })
    }

    const revokeHandle = () => {
        setLoading(true);
        let params = { ...post, status: 'PUBLISHED', riskReason: '' };
        delete params['adminTags'];
        delete params['tags'];
        delete params['events'];
        delete params['topic'];
        updatePost(post.id, params).then(res => {
            onUpdate();
            onClose();
        }).catch(err => {
            message.error(String(err));
        }).finally(() => {
            setLoading(false);
        });
    }

    const editHandle = () => {
        navigateTo(MainLayoutUrl.community.adminpost.item.path.replace(':id', post.id), { state: { detail: JSON.stringify(post) } })
    }

    const deleteHandle = () => {
        setLoading(true);
        deletePost(post.id).then(res => {
            onUpdate();
            onClose();
        }).catch(err => message.error(String(err))).finally(() => {
            setLoading(false);
        });
    }

    const buildVoteView = () => {
        if (!post.vote || parseInt(post.vote.id) <= 0 || Array.from(post.vote.options ?? []).length <= 0) {
            return <div />
        }
        const totalCount = Array.from(post.vote.options).map(item => parseInt(item.voteCount)).reduce((x, y) => (x + y));
        return (
            <div style={{ marginTop: 24 }}>
                <h5>[Vote] {post.vote.description}</h5>
                {
                    post.vote.options.map(item => {
                        const voteCount = parseInt(item.voteCount);
                        const percent = 100 * voteCount / totalCount;
                        return (
                            <div key={item.id} className="vote-item">
                                <div className="vote-bar" style={{ width: `${totalCount > 0 ? (percent + '%') : 0}` }} />
                                <div className="vote-cnt">
                                    <div className="t1">{capitalizeFirstLetter(item.content)}</div>
                                    <div className="t2">{item.voteCount} vote</div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        );
    }

    const buildTags = () => {
        let tags = Array.from(post.tags ?? []).filter((item) => item.isTopic === true || item.tag?.isTopic === true).map((item) => {
            if (item.tag) {
                return item.tag.name
            }
            return item.name
        }).join(',');
        if (tags.length <= 0) return null;
        return (
            <Space wrap style={{ margin: '8px 0' }}>
                {tags.split(',').map((item, index) => {
                    return <div className="post-topic-item" key={index}>
                        <Space>
                            <Image alt="tag" src={TopicIcon} style={{pointerEvents: 'none'}} />
                            {item}
                        </Space>
                    </div>
                })}
            </Space>
        )
    }

    const replyHandle = (comment) => {
        setCurrComment(comment);
        setModalShow(true);
    }

    const onCommentUpdate = () => {
        setCurrComment(null);
        let pSize = 10 * commentPageNum;
        setCommentPageSize(pSize);
        setCommentPageNum(1);
        setCommentLoading(false);
        getComments(false, 1, pSize);

        if (onUpdate) {
            onUpdate();
        }
    }

    const viewMoreHandle = (commentId) => {
        let params = {
            'commentId': commentId,
            'page.disable': true
        }
        fetchMoreChildComment(params).then(res => {
            if (res.list && res.list.length > 0) {
                let newData = Array.from(JSON.parse(JSON.stringify(comments)));
                let tmpComment = newData.find(item => item.id === commentId);
                if (tmpComment) {
                    tmpComment.children = res.list;
                    tmpComment.childCount = res.list.length;
                }
                setComments(newData);
            }
        })
    }

    if (!post) return <div />;
    let user = post.user;
    let category = String(post.category.name).toLowerCase();

    return (
        <div>
            <Drawer
                key={post.id}
                forceRender={true}
                title={'Post Review'}
                width={480}
                maskClosable
                onClose={onClose}
                open={open}
                extra={
                    canEdit ?
                        <Space>
                            {
                                (post.status !== 'RISK' && post.status !== 'PENDING' && !post.user.admin) && <Button danger onClick={rejectHandle}>Reject</Button>
                            }
                            {
                                ((post.status === 'RISK' && !post.riskReason) || post.status === 'PENDING') && (
                                    <>
                                        <Button type="primary" onClick={revokeHandle}>Approve</Button>
                                        <Button danger onClick={rejectHandle}>Reject</Button>
                                    </>
                                )
                            }
                            {
                                post.user.admin && (
                                    <>
                                        <Button loading={loading} type="primary" onClick={editHandle}>Edit</Button>
                                        <Popconfirm
                                            placement="bottom"
                                            title="Delete Action"
                                            description="Are you sure to delete this post?"
                                            onConfirm={deleteHandle}
                                            okText="Yes"
                                            cancelText="No" >
                                            <Button loading={loading} danger>Delete</Button>
                                        </Popconfirm>
                                    </>
                                )
                            }
                            <Button loading={loading} onClick={onClose}>Close</Button>
                        </Space> :
                        <Button loading={loading} onClick={onClose}>Close</Button>
                }
            >
                <div id={`scroll_${post.id}`} style={{ height: '100%', overflow: 'auto' }}>
                    <Space style={{ width: '100%' }}>
                        <UserInfoView user={user} showWallet={false} />
                        {buildStatus()}
                    </Space>
                    <div style={{ color: '#ccc', fontSize: 12, marginTop: 12 }}>{dayjs(post.createdAt).format('YYYY-MM-DD HH:mm')}</div>
                    <div style={{ margin: '16px 0' }}>
                        {category === 'post' ? buildPostView() : buildArticleView()}
                    </div>
                    <div className="post-content-review" id={`${from}_pcnt_${post.id}`} />

                    {buildTags()}
                    {buildVoteView()}

                    <PostCommentsView
                        loading={commentLoading}
                        comments={comments}
                        total={commentsTotal}
                        replyCallback={replyHandle}
                        viewMoreCallback={viewMoreHandle} />
                </div>
            </Drawer>

            <VirtualCommentForPostModalView
                post={post}
                replyComment={currComment}
                open={modalShow}
                onClose={() => setModalShow(false)}
                onUpdate={onCommentUpdate} />
        </div>
    )
}

export default PostReview;