import { useEffect, useState } from "react";
import { fetchTaskSetting, updateTaskSetting } from "../../api/points";
import { Descriptions, Form, Space, Input, Card, Button, message } from "antd";

const TaskSettingView = () => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();

    useEffect(() => {
        fetchTaskSetting().then(res => {
            if (res) {
                form.setFieldValue('beginnerTaskSetting', res.beginnerTaskSetting);
                form.setFieldValue('dailyTaskSetting', res.dailyTaskSetting);
            }
        })
    }, [])

    const saveHandle = () => {
        setLoading(true);
        let params = {
            beginnerTaskSetting: form.getFieldValue('beginnerTaskSetting'),
            dailyTaskSetting: form.getFieldValue('dailyTaskSetting'),
        }
        updateTaskSetting(params).then(res => {
            if(res) {
                message.success('saved!')
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const getExtra = () => {
        return (
            <Space>
                <Button type="primary" onClick={saveHandle} loading={loading}>Save</Button>
            </Space>
        )
    }

    return (
        <Form form={form}>
            <Descriptions colon bordered column={1} style={{margin: 12}} extra={getExtra()}>
                <Descriptions.Item span={1} label='Beginner Task Settings'>
                    <Form.List name={'beginnerTaskSetting'}>
                        {(subFields, subOpt) => (
                            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 16 }}>
                                {subFields.map((subField, index) => {
                                    let title = form.getFieldValue('beginnerTaskSetting')[index]?.title;
                                    return (
                                        <Card title={title} key={subField.key}>
                                            <Space >
                                                <Form.Item noStyle name={[subField.name, 'completeTimes']}>
                                                    <Input disabled={title === 'Activate Wallet'} addonBefore='Completed' style={{ width: 240 }} addonAfter='Times' />
                                                </Form.Item>

                                                <Form.Item noStyle name={[subField.name, 'point']}>
                                                    <Input addonBefore='Get' style={{ width: 240 }} addonAfter='Points' />
                                                </Form.Item>
                                            </Space>
                                        </Card>
                                    )
                                })}
                            </div>
                        )}
                    </Form.List>
                </Descriptions.Item>
                <Descriptions.Item span={1} label='Daily Task Settings'>
                    <Form.List name={'dailyTaskSetting'}>
                        {(subFields, subOpt) => (
                            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 16 }}>
                                {subFields.map((subField, index) => {
                                    let title = form.getFieldValue('dailyTaskSetting')[index]?.title;
                                    return (
                                        <Card title={title} key={subField.key}>
                                            <Space >
                                                <Form.Item noStyle name={[subField.name, 'completeTimes']}>
                                                    <Input disabled={title === 'Browse Learning Center 15s'} addonBefore='Completed' style={{ width: 240 }} addonAfter='Times' />
                                                </Form.Item>

                                                <Form.Item noStyle name={[subField.name, 'point']}>
                                                    <Input addonBefore='Get' style={{ width: 240 }} addonAfter='Points' />
                                                </Form.Item>
                                            </Space>
                                        </Card>
                                    )
                                })}
                            </div>
                        )}
                    </Form.List>
                </Descriptions.Item>
            </Descriptions>
        </Form>
    )
}

export default TaskSettingView;