import { useEffect, useState } from "react";
import { fetchCheckingSetting, updateCheckingSetting } from "../../api/points";
import { Descriptions, Form, Space, Input, Button, Typography } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { message } from "antd";

const PointSettingView = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchCheckingSetting().then(res => {
            if (res) {
                form.setFieldValue('day1PointSetting', res.day1PointSetting)
                form.setFieldValue('day2PointSetting', res.day2PointSetting)
                form.setFieldValue('day3PointSetting', res.day3PointSetting)
                form.setFieldValue('day4PointSetting', res.day4PointSetting)
                form.setFieldValue('day5PointSetting', res.day5PointSetting)
                form.setFieldValue('day6PointSetting', res.day6PointSetting)
                form.setFieldValue('day7PointSetting', res.day7PointSetting)
            }
        })
    }, [])

    const saveHandle = () => {
        let last = form.getFieldValue('day7PointSetting') ?? [];
        if(last.length <= 0) {
            message.error('The 7th day percentage is not 100%')
            return;
        }
        let percentSum = last.map(item => parseInt(item.percentage)).reduce((x, y) => (x + y));
        if(percentSum !== 100) {
            message.error('The 7th day percentage is not 100%')
            return;
        }
        setLoading(true);
        let params = {
            day1PointSetting: form.getFieldValue('day1PointSetting') ?? [],
            day2PointSetting: form.getFieldValue('day2PointSetting') ?? [],
            day3PointSetting: form.getFieldValue('day3PointSetting') ?? [],
            day4PointSetting: form.getFieldValue('day4PointSetting') ?? [],
            day5PointSetting: form.getFieldValue('day5PointSetting') ?? [],
            day6PointSetting: form.getFieldValue('day6PointSetting') ?? [],
            day7PointSetting: form.getFieldValue('day7PointSetting') ?? [],
        }
        updateCheckingSetting(params).then(res => {
            if (res) {
                message.success('saved!')
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const getExtra = () => {
        return (
            <Space>
                <Button type="primary" onClick={saveHandle} loading={loading}>Save</Button>
            </Space>
        )
    }

    return (
        <Form form={form}>
            <Descriptions colon bordered column={1} style={{ margin: 12 }} extra={getExtra()}>
                <Descriptions.Item span={1} label='Sign in for 1 day'>
                    <Form.List name={'day1PointSetting'}>
                        {(subFields, subOpt) => (
                            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 16 }}>
                                {subFields.map((subField) => (
                                    <Space key={subField.key}>
                                        <Typography.Text>Get</Typography.Text>
                                        <Form.Item noStyle name={[subField.name, 'point']}>
                                            <Input style={{ width: 150 }} addonAfter='Points' />
                                        </Form.Item>
                                    </Space>
                                ))}
                            </div>
                        )}
                    </Form.List>
                </Descriptions.Item>
                <Descriptions.Item span={1} label='Sign in for 2 days'>
                    <Form.List name={'day2PointSetting'}>
                        {(subFields, subOpt) => (
                            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 16 }}>
                                {subFields.map((subField) => (
                                    <Space key={subField.key}>
                                        <Typography.Text>Get</Typography.Text>
                                        <Form.Item noStyle name={[subField.name, 'point']}>
                                            <Input style={{ width: 150 }} addonAfter='Points' />
                                        </Form.Item>
                                    </Space>
                                ))}
                            </div>
                        )}
                    </Form.List>
                </Descriptions.Item>
                <Descriptions.Item span={1} label='Sign in for 3 days'>
                    <Form.List name={'day3PointSetting'}>
                        {(subFields, subOpt) => (
                            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 16 }}>
                                {subFields.map((subField) => (
                                    <Space key={subField.key}>
                                        <Typography.Text>Get</Typography.Text>
                                        <Form.Item noStyle name={[subField.name, 'point']}>
                                            <Input style={{ width: 150 }} addonAfter='Points' />
                                        </Form.Item>
                                    </Space>
                                ))}
                            </div>
                        )}
                    </Form.List>
                </Descriptions.Item>
                <Descriptions.Item span={1} label='Sign in for 4 days'>
                    <Form.List name={'day4PointSetting'}>
                        {(subFields, subOpt) => (
                            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 16 }}>
                                {subFields.map((subField) => (
                                    <Space key={subField.key}>
                                        <Typography.Text>Get</Typography.Text>
                                        <Form.Item noStyle name={[subField.name, 'point']}>
                                            <Input style={{ width: 150 }} addonAfter='Points' />
                                        </Form.Item>
                                    </Space>
                                ))}
                            </div>
                        )}
                    </Form.List>
                </Descriptions.Item>
                <Descriptions.Item span={1} label='Sign in for 5 days'>
                    <Form.List name={'day5PointSetting'}>
                        {(subFields, subOpt) => (
                            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 16 }}>
                                {subFields.map((subField) => (
                                    <Space key={subField.key}>
                                        <Typography.Text>Get</Typography.Text>
                                        <Form.Item noStyle name={[subField.name, 'point']}>
                                            <Input style={{ width: 150 }} addonAfter='Points' />
                                        </Form.Item>
                                    </Space>
                                ))}
                            </div>
                        )}
                    </Form.List>
                </Descriptions.Item>
                <Descriptions.Item span={1} label='Sign in for 6 days'>
                    <Form.List name={'day6PointSetting'}>
                        {(subFields, subOpt) => (
                            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 16 }}>
                                {subFields.map((subField) => (
                                    <Space key={subField.key}>
                                        <Typography.Text>Get</Typography.Text>
                                        <Form.Item noStyle name={[subField.name, 'point']}>
                                            <Input style={{ width: 150 }} addonAfter='Points' />
                                        </Form.Item>
                                    </Space>
                                ))}
                            </div>
                        )}
                    </Form.List>
                </Descriptions.Item>
                <Descriptions.Item span={1} label='Sign in for 7 days'>
                    <Form.List name={'day7PointSetting'}>
                        {(subFields, subOpt) => (
                            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 16 }}>
                                {subFields.map((subField) => (
                                    <Space key={subField.key}>
                                        <Form.Item noStyle name={[subField.name, 'percentage']}>
                                            <Input style={{ width: 150 }} addonAfter='%' />
                                        </Form.Item>
                                        <Typography.Text>chance to get</Typography.Text>
                                        <Form.Item noStyle name={[subField.name, 'point']}>
                                            <Input style={{ width: 150 }} addonAfter='Points' />
                                        </Form.Item>
                                        <CloseOutlined
                                            onClick={() => {
                                                subOpt.remove(subField.name);
                                            }}
                                        />
                                    </Space>
                                ))}
                                <Button style={{ width: 200, fontSize: 12 }} type="dashed" onClick={() => subOpt.add()} block>
                                    + Add More Percent
                                </Button>
                            </div>
                        )}
                    </Form.List>
                </Descriptions.Item>
            </Descriptions>
        </Form>
    )
}

export default PointSettingView;