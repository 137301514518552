import { PlusOutlined } from "@ant-design/icons";
import { Table, Space, Popconfirm, Button, Input, Pagination, message, Flex, Typography, Tag } from "antd";
import { useState, useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";
import { fetchHomePageHotTag, deleteHomePageHotTag } from "../../api/homepage";
import { conversionUtcDate } from "../../utils/comm";
import HotTagEditView from "../../components/homepage/hot_tag_edit_view";

const HomeTopicScreen = () => {
    const [tabData, setTabData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [keyword, setKeyword] = useState();
    const [open, setOpen] = useState(false);
    const [currTopic, setCurrTopic] = useState(null);
    const tabCols = [
        {
            title: 'Index',
            dataIndex: 'id',
            key: 'id',
            render: (text, _, index) => ((pageNum - 1) * pageSize + index + 1),
        },
        {
            title: 'Topic',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {
                return (
                    <Typography.Text mark>{`#${record.tag.name}`}</Typography.Text>
                );
            },
        },
        {
            title: 'Weight',
            dataIndex: 'weight',
            key: 'weight',
            render: (text, _) => {
                if (String(text) === '0') return '-';
                return (
                    <b>{text}</b>
                );
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, _) => {
                if (String(text).toLowerCase() === 'active') {
                    return <Tag color="green">{text}</Tag>
                }
                return <Tag color="red">{text}</Tag>
            },
        },
        {
            title: 'CreatedAt',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, _) => {
                return conversionUtcDate(text, null, true);
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <Button.Group size='small'>
                        <Button type='link' onClick={() => editHandle(record)}>Edit</Button>
                        <Popconfirm
                            title="Delete"
                            description='Are you sure to delete this hot topic?'
                            onConfirm={() => deleteHandle(record)}
                            okText="Yes"
                            cancelText="No" >
                            <Button danger type='link'>Delete</Button>
                        </Popconfirm>
                    </Button.Group>
                )
            }
        }
    ];

    useEffect(() => {
        getData(pageNum, pageSize, keyword);
    }, [])

    const getData = useDebouncedCallback((pNum, pSize, search) => {
        let params = {
            'page.num': pNum,
            'page.size': pSize,
            'name': search,
        }
        setLoading(true);
        fetchHomePageHotTag(params).then(res => {
            if (res.list) {
                setTabData(res.list);
            }
            if (res.page) {
                setTotal(res.page.total);
            }
        }).finally(() => setLoading(false))
    }, 300);

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        getData(page, size, keyword);
    }

    const onKeywordChange = (value) => {
        setKeyword(value);
        setPageNum(1);
        getData(1, pageSize, value);
    }

    const editHandle = (record) => {
        setCurrTopic(record);
        setOpen(true);
    }

    const deleteHandle = (record) => {
        setLoading(true);
        deleteHomePageHotTag(record.id).then(res => {
            setLoading(false);
            getData(pageNum, pageSize, keyword);
        }).catch(err => {
            message.error(err);
            setLoading(false);
        });
    }

    const addNewHandle = () => {
        setCurrTopic(null);
        setOpen(true);
    }

    return (
        <div className='main-wrapper'>
            <div className='top-part cnt-item'>
                <Flex justify='space-between'>
                    <Button type='primary' icon={<PlusOutlined />} onClick={addNewHandle}>Add Hot Topic</Button>
                    <Space>
                        <Input
                            placeholder="keywords search"
                            allowClear
                            onChange={evt => onKeywordChange(evt.target.value)}
                        />
                    </Space>
                </Flex>
            </div>
            <div className='cnt-item'>
                <Table
                    loading={loading}
                    rowKey={(record) => record.id}
                    rowClassName={'table-row'}
                    size='large'
                    dataSource={tabData}
                    columns={tabCols}
                    pagination={false}
                    expandable={{ rowExpandable: (record) => false, showExpandColumn: false }} />
            </div>
            <div className='footer-part cnt-item'>
                <Pagination
                    total={total}
                    showTotal={(total) => `total ${total}`}
                    current={pageNum}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    pageSizeOptions={[10, 20, 30, 50]}
                    onChange={(page, size) => pageChange(page, size)}
                />
            </div>

            <HotTagEditView
                open={open}
                defaultTag={currTopic}
                onClose={() => setOpen(false)}
                onUpdate={() => getData(pageNum, pageSize, keyword)} />
        </div>
    )
}

export default HomeTopicScreen;