import React, { useEffect } from 'react';
import * as echarts from 'echarts/core';
import {
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent
} from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { getWeekdayDesc } from '../../utils/comm';

echarts.use([
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    LineChart,
    CanvasRenderer,
    UniversalTransition
]);

const CheckingPlotsView = ({ statics }) => {
    useEffect(() => {
        if (!statics) return;
        
        let xdata = Array.from(statics ?? []).map(item => {
            let date = new Date(item.date);
            return getWeekdayDesc(date.getDay());
        });
        let ydata = Array.from(statics ?? []).map(item => parseInt(item.number));

        let chartDom;
        let plotChart;
        chartDom = document.getElementById('checkingPlotChart');
        plotChart = echarts.init(chartDom);
        let option = {
            tooltip: {
                trigger: 'axis'
            },
            grid: {
                bottom: 40,
                left: 40,
                right: 20,
                top: 20,
            },
            legend: {
                lineStyle: {
                    color: '#ccc',
                },
                right: 0,
            },
            toolbox: {
                show: false,
                feature: {
                    dataZoom: {
                        yAxisIndex: 'none'
                    },
                    dataView: { readOnly: false },
                    magicType: { type: ['line', 'bar'] },
                    restore: {},
                    saveAsImage: {}
                }
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: xdata,
                splitLine: {
                    show: false
                }
            },
            yAxis: {
                position: 'left',
                type: 'value',
                splitLine: {
                    show: false
                },
                axisLabel: {
                    formatter: '{value}'
                }
            },
            series: [
                {
                    name: '',
                    type: 'line',
                    data: ydata,
                    smooth: false,
                    symbol: 'circle',
                    symbolSize: 8,
                    showSymbol: false,
                    lineStyle: {
                        width: 2,
                        color: '#04d182'
                    }
                },
            ]
        };
        plotChart.setOption(option);
    }, [statics]);


    return (
        <div id='checkingPlotChart' style={{ height: 400, width: '100%' }} />
    );
};

export default CheckingPlotsView;