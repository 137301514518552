import { Button, Modal, Typography, Spin, message, Input, Pagination, Card, Flex } from "antd";
import { useEffect, useState } from "react";
import { createTag, deleteTag, fetchAllTags, updateTag } from "../../api/community";
import ContentTagItemEditView from "./content_tag_item_edit_view";
import { useDebouncedCallback } from "use-debounce";

const TagChildrenModalView = ({ defaultTag, onUpdate, onClose, open }) => {
    const [tags, setTags] = useState([]);
    const [isDiff, setIsDiff] = useState(false);
    const [loading, setLoading] = useState(false);
    const [oldTags, setOldTags] = useState([]);
    const [keywords, setKeywords] = useState(null);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        if (defaultTag && parseInt(defaultTag.id) > 0) {
            getData(pageNum, pageSize, keywords);
        }
    }, [defaultTag])

    const getData = useDebouncedCallback(
        (pNum, pSize, search) => {
            setLoading(true);
            let params = {
                'page.num': pNum,
                'page.size': pSize,
                'level': 1,
                'parentId': defaultTag.id,
                'name': search
            };
            fetchAllTags(params).then(res => {
                if (res.list) {
                    setTags(JSON.parse(JSON.stringify(res.list)));
                    setOldTags(JSON.parse(JSON.stringify(res.list)));
                }
                if (res.page) {
                    setTotal(res.page.total);
                }
            }).finally(() => setLoading(false))
        }, 500
    )

    useEffect(() => {
        let diffs = getDiffTags();
        setIsDiff(diffs.length > 0 ? true : false);

        if (diffs.findIndex(item => parseInt(item.id ?? '0') <= 0) >= 0) {
            toSubmit();
        }
    }, [tags])

    useEffect(() => {
        if (!open) {
            setKeywords(null);
        }
    }, [open])

    const getDiffTags = () => {
        let arr = Array.from(tags).filter(item => {
            if (!oldTags.map(old => old.name).includes(item.name)) {
                return true;
            }
            return false;
        });
        return arr;
    }

    const toSubmit = () => {
        setLoading(true);
        let chs = getDiffTags().map(item => ({
            id: (item.id && parseInt(item.id) > 0) ? item.id : undefined,
            level: 1,
            parentId: defaultTag.id,
            name: item.name
        }));
        let created = chs.filter(item => !item.id);
        let updated = chs.filter(item => item.id);
        let reqArr = [];
        if (created.length) {
            reqArr.push(createTag(created[0]));
        }
        if (updated.length) {
            reqArr.push(updateTag({ list: updated }))
        }
        Promise.all(reqArr).then(res => {

            if (created.length) {
                setPageNum(1);
                setKeywords(null);
                getData(pageNum, pageSize, null);
            } else {
                getData(pageNum, pageSize, keywords);
            }
            onUpdate();
        }).catch(err => {
            message.error(err.message);
            setLoading(false);
        })
    }

    const onDelete = (tagId) => {
        deleteTag(tagId).then(res => {
            getData(pageNum, pageSize, keywords);
            onUpdate();
        }).catch(err => {
            message.error(String(err));
        })
    }

    const pageChange = (pNum, pSize) => {
        let num = pageSize == pSize ? pNum : 1;
        setPageNum(num);
        setPageSize(pSize);

        getData(num, pSize, keywords);
    }

    if (!defaultTag) return <div />
    return (
        <Modal
            centered open={open}
            title={
                <div style={{ color: '#afafaf' }}>
                    <Typography.Text mark style={{ fontSize: 16 }}><b>{`#${defaultTag.name}`}</b></Typography.Text>
                    &nbsp; - second level
                </div>
            }
            width={1000}
            onCancel={() => {
                setTags([]);
                setOldTags([]);
                onClose();
            }}
            footer={[
                <Button style={{ width: 140 }} key={'diff'} onClick={toSubmit} disabled={!isDiff} loading={loading} type="primary">Save</Button>
            ]}>
            <div style={{ padding: '12px 0' }}>
                <Flex justify="space-between" align="center">
                    <Input
                        style={{ width: 240 }}
                        value={keywords}
                        placeholder="search tags"
                        allowClear
                        onChange={(evt) => {
                            setPageNum(1);
                            setKeywords(evt.target.value);
                            getData(1, pageSize, evt.target.value);
                        }} />

                    <Pagination
                        total={total}
                        showTotal={(total) => `total ${total}`}
                        current={pageNum}
                        pageSize={pageSize}
                        showSizeChanger={true}
                        pageSizeOptions={[20, 50, 100]}
                        onChange={(page, size) => pageChange(page, size)}
                    />
                </Flex>

                <div style={{ height: 24 }} />
                <Card loading={loading} styles={{ body: { height: '50vh', overflowY: 'auto' } }}>
                    <ContentTagItemEditView
                        defaultTags={tags}
                        onUpdate={(newTags) => setTags(newTags)}
                        onDelete={onDelete}
                    />
                </Card>
            </div>
        </Modal>
    )
}

export default TagChildrenModalView;