import { useEffect, useState } from "react";
import { fetchDataOverview } from "../../api/points";
import { conversionUtcDate } from "../../utils/comm";
import { Space, Statistic, Card, Row, Col, Radio } from "antd";
import CountUp from "react-countup";
import CheckingPlotsView from "../../components/points/checking_plots_view";
import PointMarkLineView from "../../components/points/points_markline_view";

const PointDataScreen = () => {
    const [flag, setFlag] = useState(0);
    const [statics, setStatics] = useState();

    useEffect(() => {
        getData(flag);
    }, [])

    const formatter = (value) => <CountUp end={value} separator="," />;

    const getData = (type) => {
        let checkinStatisticsEnd = conversionUtcDate(new Date(), 'UTC');

        let today = new Date(checkinStatisticsEnd);
        let checkinStatisticsStart = new Date(checkinStatisticsEnd);
        checkinStatisticsStart.setDate(today.getDate() - 7);

        let pointTrendEnd = checkinStatisticsEnd;
        let lastDate = new Date(pointTrendEnd);
        if (type === 0) {
            lastDate.setDate(today.getDate() - 49);
        } else {
            lastDate.setDate(today.getDate() - 210);
        }

        let params = {
            checkinStatisticsStart: conversionUtcDate(checkinStatisticsStart, 'UTC'),
            checkinStatisticsEnd: checkinStatisticsEnd,
            pointTrendUnit: type,
            pointTrendStart: conversionUtcDate(lastDate, 'UTC'),
            pointTrendEnd: pointTrendEnd
        }

        fetchDataOverview(params).then(res => {
            if (res) {
                setStatics({
                    ...res,
                    checkinNumbers: res.checkinNumbers.sort((a, b) => {
                        return new Date(a.date).getTime() - new Date(b.date).getTime();
                    }),
                    pointTrends: res.pointTrends.sort((a, b) => {
                        return new Date(a.date).getTime() - new Date(b.date).getTime();
                    }),
                });
            }
        })
    }

    const getExtra = () => {
        return (
            <Radio.Group
                buttonStyle='solid'
                value={flag}
                onChange={evt => {
                    setFlag(evt.target.value);
                    getData(evt.target.value);
                }} >
                <Radio.Button value={0}>Week</Radio.Button>
                <Radio.Button value={1}>Month</Radio.Button>
            </Radio.Group>
        )
    }

    return (
        <div style={{ margin: 24 }}>
            <Space size={[16, 12]}>
                <Card bordered={false}>
                    <Statistic title='Daily checked in' value={statics?.todayCheckinNumber ?? '0'} formatter={formatter} />
                </Card>
                <Card bordered={false}>
                    <Statistic title="Daily task participants" value={statics?.todayTaskNumber ?? '0'} formatter={formatter} />
                </Card>
                <Card bordered={false}>
                    <Statistic title="Total checked in" value={statics?.historyCheckinNumber ?? '0'} formatter={formatter} />
                </Card>
                <Card bordered={false}>
                    <Statistic title="Total redeem coins" value={statics?.historyRedeemCoins ?? '0'} />
                </Card>
            </Space>
            <Row gutter={16} style={{ marginTop: 24 }}>
                <Col span={12}>
                    <Card bordered={false} title='Checked-in Trend'>
                        <CheckingPlotsView statics={statics?.checkinNumbers} />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card bordered={false} title='Points Trend' extra={getExtra()}>
                        <PointMarkLineView statics={statics?.pointTrends} flag={flag} />
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default PointDataScreen;