import { Tabs } from "antd";
import PointSettingView from "../../components/points/point_setting_view";
import TaskSettingView from "../../components/points/task_setting_view";


const PointSettingScreen = () => {
    const tabItems = () => {
        return [
            {
                label: 'Checking Setting',
                key: '1',
                children: <PointSettingView />,
            },
            {
                label: 'Task Setting',
                key: '2',
                children: <TaskSettingView />
            },
        ]
    }

    return (
        <div className='main-wrapper'>
            <div className='cnt-item'>
                <Tabs tabBarStyle={{padding: '10px 10px 0 10px'}} items={tabItems()} />
            </div>
        </div>
    )
}

export default PointSettingScreen;