import React, { useEffect } from 'react';
import * as echarts from 'echarts/core';
import {
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    MarkLineComponent,
    MarkPointComponent
} from 'echarts/components';
import { BarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { conversionUtcDate, getMonthDesc } from '../../utils/comm';

echarts.use([
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    MarkLineComponent,
    MarkPointComponent,
    BarChart,
    CanvasRenderer
]);

const PointMarkLineView = ({ flag, statics }) => {
    useEffect(() => {
        if(!statics) return;

        let issuedData = Array.from(statics).map(item => parseInt(item.quantityIssued));
        let consumedData = Array.from(statics).map(item => parseInt(item.quantityConsumed));
        let xdata =  Array.from(statics).map(item => item.date);
        if(flag === 0) {
            xdata = xdata.map(item => {
                let start = conversionUtcDate(item, null, false, 'MM/DD');
                let end = new Date(conversionUtcDate(item));
                end.setDate(new Date(conversionUtcDate(item)).getDate() + 6);
                end = conversionUtcDate(end, null, false, 'MM/DD');
                return `${start}\n${end}`;
            })
        } else if(flag === 1) {
            xdata = xdata.map(item => {
                let date = new Date(conversionUtcDate(item));
                return getMonthDesc(date.getMonth());
            })
        }

        let chartDom;
        let plotChart;
        chartDom = document.getElementById('pointMarkLineChart');
        plotChart = echarts.init(chartDom);
        let option = {
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                data: ['Issued', 'Consumption']
            },
            grid: {
                bottom: 40,
                left: 50,
                right: 20,
                top: 40,
            },
            calculable: true,
            xAxis: [
                {
                    type: 'category',
                    data: xdata
                }
            ],
            yAxis: [
                {
                    type: 'value',
                }
            ],
            series: [
                {
                    name: 'Issued',
                    type: 'bar',
                    data: issuedData,
                },
                {
                    name: 'Consumption',
                    type: 'bar',
                    data: consumedData,
                }
            ]
        };
        plotChart.setOption(option);
    }, [statics]);


    return (
        <div id='pointMarkLineChart' style={{ height: 400, width: '100%' }} />
    );
};

export default PointMarkLineView;