import request from "../utils/request";

export function fetchPointsBalance(params) {
    return request.get('/point/balance', params);
}

export function fetchPointsHistory(params) {
    return request.get('/point/balance/history', params);
}

export function fetchPointsProduct(params) {
    return request.get('/point/products', params);
}

export function createPointsProduct(params) {
    return request.post('/point/product/create', params);
}

export function updatePointsProduct(params) {
    return request.patch('/point/product/edit', params);
}

export function fetchTaskSetting(params) {
    return request.get('/point/task/setting', params)
}

export function updateTaskSetting(params) {
    return request.patch('/point/task/setting/edit', params);
}

export function fetchDataOverview(params) {
    return request.get('/point/data/viewing', params);
}

export function fetchCheckingSetting(params) {
    return request.get('/point/checkin/setting', params);
}

export function updateCheckingSetting(params) {
    return request.patch('/point/checkin/setting/edit', params);
}